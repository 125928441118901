import React, { FC, Fragment, ReactNode } from 'react';
import { FloatingPortal, UseHoverProps } from '@floating-ui/react';
import cx from 'classnames';
import { useTooltip } from './hooks/useTooltip';
import { TToolTipDirection } from './types';

type TUnstyledTooltipProps = {
  cursor?: 'pointer' | 'default';
  direction?: TToolTipDirection;
  trigger: ReactNode;
  children: ReactNode;
  floatingUIHover?: UseHoverProps;
  className?: string;
  tag?: 'div' | typeof Fragment;
};

export const UnstyledTooltip: FC<TUnstyledTooltipProps> = ({
  cursor = 'pointer',
  direction = 'bottom',
  children,
  trigger,
  floatingUIHover = {},
  className,
  tag: Tag = 'div',
}) => {
  const { active, x, y, refs, strategy, getReferenceProps, getFloatingProps } = useTooltip(direction, floatingUIHover);
  return (
    <Tag>
      <div
        className={cx(className, {
          'cursor-pointer': cursor === 'pointer',
        })}
        ref={refs.setReference}
        {...getReferenceProps()}
      >
        {trigger}
      </div>
      <FloatingPortal>
        {active && (
          <div
            ref={refs.setFloating}
            style={{
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
              zIndex: 100,
            }}
            {...getFloatingProps()}
          >
            {children}
          </div>
        )}
      </FloatingPortal>
    </Tag>
  );
};
