import { Territory as TTerritory, User as TUser } from 't2-graphql-types';

export type TMutualTerritoriesProps = Pick<TTerritory, 'id' | 'name' | 'slug' | 'logo'>;

export type TUserProps = Pick<
  TUser,
  'name' | 'username' | 'profileImage' | 'bio' | 'cumulativeTimePoints' | 'articlesCount'
> & {
  promptsCount: number;
  territories: TMutualTerritoriesProps[];
};

export type TT2ProfileCardProps = {
  user: TUserProps;
  isCurrentlyLoggedInUser?: boolean;
  onClick?: () => void;
};

export const enum T2ProfileCardDetailsElementType {
  TimePoints = 'timePoints',
  ArticleCount = 'articleCount',
  PromtCount = 'promtCount',
}
